import { template as template_ee29291113954e849742ca9c0be18d8f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ee29291113954e849742ca9c0be18d8f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
