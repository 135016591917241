import { template as template_b1b4243466d344e48f0b7e770f10fbd9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_b1b4243466d344e48f0b7e770f10fbd9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
