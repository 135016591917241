import { template as template_5486a4a15ea2469e9ec8fa2ebe4d5d82 } from "@ember/template-compiler";
const FKLabel = template_5486a4a15ea2469e9ec8fa2ebe4d5d82(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
