import { template as template_3efa354d560c47ccb2859a6eb2a54d6c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_3efa354d560c47ccb2859a6eb2a54d6c(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
