import { template as template_c870ec9e0d1048c793a4a54e7c44723e } from "@ember/template-compiler";
const FKText = template_c870ec9e0d1048c793a4a54e7c44723e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
